import { useCallback } from 'react';
import {
  TeeOfferFilter, SortDir, SortParam,
} from 'generated/types';
import { useLazyGetMinimalConfigurationQuery } from 'generated/schemas/teeOffers';
import { useLazyGetMatchingTeeSlotsQuery } from 'lib/features/teeOffers';
import { validateRTKResponse } from 'lib/features/helpers';
import { useGetTeeOfferFilterFromOfferConfiguration } from './useGetTeeOfferFilterFromOfferConfiguration';

export const MATCH_SLOTS_DEFAULT_SORT: SortParam[] = [
  { sortBy: 'teeOffer.stats.ordersInQueue', sortDir: SortDir.Asc },
  { sortBy: 'price', sortDir: SortDir.Asc },
  { sortBy: 'slotTotalValues', sortDir: SortDir.Asc },
  { sortBy: 'optionTotalValues', sortDir: SortDir.Asc },
];

export const getMinFromNewAndProcessing = (edges) => {
  const sortMinNew = edges
    .map((item) => item.node)
    .sort((a, b) => ((a?.teeOffer?.stats?.new || 0) - (b?.teeOffer?.stats?.new || 0)));
  if (sortMinNew.length) {
    const minNew = (sortMinNew ?? [])
      .filter((item) => ((item?.teeOffer?.stats?.new || 0) === (sortMinNew[0]?.teeOffer?.stats?.new || 0)));
    if (!minNew.length) return null;
    const sortMinProcessing = minNew
      .sort((a, b) => ((a?.teeOffer?.stats?.processing || 0) - (b?.teeOffer?.stats?.processing || 0)));
    return sortMinProcessing[0];
  }
  return null;
};

export const useGetFirstMatchingTeeConfiguration = () => {
  const [fetchMatchingTeeSlots] = useLazyGetMatchingTeeSlotsQuery();
  const [getMinimalConfiguration] = useLazyGetMinimalConfigurationQuery();
  const { getFilters } = useGetTeeOfferFilterFromOfferConfiguration();

  const getFirstMatchingTeeConfiguration = useCallback(async (offers: string[][], filters?: TeeOfferFilter) => {
    const minimalConfigurationResponse = validateRTKResponse(await getMinimalConfiguration({ offers }));
    const minimalConfiguration = minimalConfigurationResponse?.data?.result;
    const matchinTeeSlots = validateRTKResponse(await fetchMatchingTeeSlots({
      filter: {
        ...getFilters(minimalConfiguration), inactive: false, enabled: true, ...filters,
      },
      pagination: {
        sort: MATCH_SLOTS_DEFAULT_SORT,
      },
    }));
    const { edges } = matchinTeeSlots?.data?.result?.page ?? {};
    return edges ? getMinFromNewAndProcessing(edges) : null;
  }, [fetchMatchingTeeSlots, getFilters, getMinimalConfiguration]);

  return {
    getFirstMatchingTeeConfiguration,
  };
};