import { useAuth } from 'hooks/auth/useAuth';
import { useSPFile, UseSPFile } from './useSPFile';
import { useSecretKeeperFile, UseSecretKeeperFile } from './useSecretKeeperFile';

export type UseFile = UseSPFile | UseSecretKeeperFile;

export const useFile = (fileId?: string | null): UseFile => {
  const { isProviderSPProvider } = useAuth();
  const spFile = useSPFile(fileId);
  const secretKeeperFile = useSecretKeeperFile(fileId);

  return isProviderSPProvider ? spFile : secretKeeperFile;
};