import { useMemo } from 'react';
import { useAppSelector } from 'lib/hooks';
import { buildOrderFormSelector } from 'lib/features/createOrderV2/selectors';
import {
  Offer, TeeOffer, TIdSource, OfferType,
} from 'generated/types';
import { OfferType as OfferTypeSdk } from '@super-protocol/sdk-js';
import { useGetOfferQuery, useGetOfferTypeQuery } from 'lib/features/offers';
import { ValueOfferSubtype, TeeOfferSubtype } from '@super-protocol/sdk-js';
import {
  HardwareInfo,
  TeeOfferSlot,
  TeeOfferOption,
} from 'generated/types';
import {
  BuildOrderForm, Slots,
} from 'lib/features/createOrderV2/types';
import {
  getFieldBuildOrderdByOfferType, getSelectedSlot, checkAddContent, getOfferPriceBySlots, getDefaultSlot,
} from 'lib/features/createOrderV2/helpers';
import {
  getTableDate,
} from 'utils';
import {
  getCategoriesByConfiguration, getTeeOfferSubTypeName, getValueOfferSubTypeName, getFixedDeposit,
} from 'utils/sdk/utils';
import {
  convertSlotInfo, convertOptionData, convertSlotUsageInfo, priceTypeMap,
  getSortedSlots,
} from 'utils/slots';
import { TOKEN_NAME } from 'common/constants';
import { useGetTeeOfferQuery } from 'lib/features/teeOffers';
import { TransformedOffer } from 'lib/features/offers/types';
import { Description, DescriptionType } from 'components/OfferAbout/types';
import { Configuration } from '../Left/types';
import { RightProps, RighListComponent } from '../Right/types';
import { Data } from '../types';
import { getOfferSlots, getSourceType, prepareModelFormats, getDefaultSelectedModelFormat } from './helpers';
import { useGetDescription } from './useGetDescription';


const prepareOfferRightData = (offer: TransformedOffer) => {
  const data: RightProps['list'] = [];
  if (!offer) return data;

  const {
    id, offerInfo, providerInfo, origins, disabledAfter, configuration, versions, slots,
  } = offer;
  const { subType } = offerInfo || {};

  if (id) data.push({ value: id, title: 'Offer ID:' });

  const subTypeName = getValueOfferSubTypeName(subType as ValueOfferSubtype);
  if (subTypeName) data.push({ value: subTypeName, title: 'Offer Type:' });

  if (providerInfo?.name) data.push({ value: providerInfo?.name, title: 'Provider:' });

  const categories = getCategoriesByConfiguration(configuration, offerInfo.subType as ValueOfferSubtype);
  if (offerInfo.subType === ValueOfferSubtype.ValueSubtypeModel && categories?.length) {
    data.push({ value: categories, title: 'Category:' });
  }

  const parameters = (slots || []).find((slot) => slot?.transformedMetadata?.parameters)?.transformedMetadata?.parameters || null;
  if (parameters) data.push({ value: parameters, title: 'Parameters:' });

  const { url } = versions?.[(versions?.length || 0) - 1]?.info?.transformedMetadata || {};
  const sourceType = getSourceType(url);
  if (url && sourceType) {
    data.push({
      value: url, component: RighListComponent.link, title: 'Source:', data: sourceType,
    });
  }

  data.push({ value: getTableDate(origins?.createdDate), title: 'Published:' });

  if (disabledAfter) data.push({ value: disabledAfter.toString(), title: 'Disabled After:' });

  data.push({ value: getTableDate(origins?.modifiedDate), title: 'Updated:' });

  return data;
};

export const offerDataTransform = (
  data: TransformedOffer,
  buildOrderForm: BuildOrderForm,
  description: Description,
): Data | undefined => {
  if (!data) return undefined;

  const {
    id, offerInfo, slots,
  } = data;
  const field = getFieldBuildOrderdByOfferType(offerInfo?.subType as ValueOfferSubtype, 'ValueOfferSubtype');
  const selectedSlots = getSelectedSlot(buildOrderForm, field, id);
  const subType = getValueOfferSubTypeName(offerInfo.subType as ValueOfferSubtype);
  const modelFormats = prepareModelFormats(slots);
  const defaultSlot = getDefaultSlot(slots);
  const defaultSelectedModelFormat = getDefaultSelectedModelFormat(modelFormats, defaultSlot, selectedSlots);

  const isAdded = checkAddContent({
    formContent: buildOrderForm,
    field,
    offerId: id,
  });

  return {
    common: {
      price: getOfferPriceBySlots(selectedSlots, slots),
      restrictions: offerInfo?.restrictions?.offers || [],
      fieldBuildOrderForm: field,
      id,
      selectedSlots,
      isAdded,
    },
    left: {
      name: offerInfo.name,
      about: description,
      restricted: offerInfo.restrictions?.offers || [],
      type: offerInfo.offerType as OfferTypeSdk,
      slots: getOfferSlots(slots, offerInfo.subType as ValueOfferSubtype),
      subType,
      options: [],
      modelFormats,
      defaultSelectedModelFormat,
    },
    right: {
      list: prepareOfferRightData(data),
    },
  };
};

export const getTEEConfiguration = (hardwareInfo: HardwareInfo): Configuration => {
  if (!hardwareInfo) return { slots: [], options: [] };
  const { slotInfo, optionInfo } = hardwareInfo || {};
  return {
    slots: convertSlotInfo({ slotInfo }),
    options: convertOptionData(optionInfo ? { optionInfo: [{ option: optionInfo, count: 1 }] } : {}),
  };
};

const getTeeOfferSlots = (slots?: TeeOfferSlot[]) => {
  return getSortedSlots<TeeOfferSlot>(slots || [])
    .map((slot) => ({
      id: {
        label: 'slotId',
        value: slot?.id,
        id: slot?.id,
      },
      title: {
        label: priceTypeMap[slot?.usage?.priceType] || '',
        value: `${getFixedDeposit({ deposit: slot?.usage?.price })} ${TOKEN_NAME}`,
        id: slot?.usage?.priceType,
      },
      list: [
        convertSlotInfo({ slotInfo: slot?.info, keys: ['cpuCores', 'ram', 'diskUsage'] }),
        convertSlotInfo({ slotInfo: slot?.info, keys: ['gpuCores', 'vram'] }),
        convertSlotUsageInfo({ slotUsage: slot?.usage, keys: ['minTimeMinutes', 'maxTimeMinutes'] }),
      ],
    }));
};

const getTeeOfferOptions = (options?: TeeOfferOption[]) => {
  return (options || [])
    .map((option) => ({
      id: {
        label: 'optionId',
        value: option?.id,
        id: option?.id,
      },
      title: {
        label: priceTypeMap[option?.usage?.priceType] || '',
        value: `${getFixedDeposit({ deposit: option?.usage?.price })} ${TOKEN_NAME}`,
        id: option?.usage?.priceType,
      },
      list: [
        convertOptionData({
          optionInfo: option?.info ? [{ option: option.info, count: 1 }] : [],
          keys: ['bandwidth', 'traffic', 'externalPort'],
        }),
        convertSlotUsageInfo({ slotUsage: option?.usage, keys: ['minTimeMinutes', 'maxTimeMinutes'] }),
      ],
    }));
};

const prepareTeeOfferRightData = (offer: TeeOffer) => {
  const data: RightProps['list'] = [];
  if (!offer) return data;

  const {
    id, providerInfo, origins, disabledAfter, teeOfferInfo,
  } = offer;

  if (id) data.push({ value: id, title: 'Offer ID:' });

  if (providerInfo?.name) data.push({ value: providerInfo?.name, title: 'Provider:' });

  const subTypeName = getTeeOfferSubTypeName(teeOfferInfo?.subType as TeeOfferSubtype);
  if (subTypeName) data.push({ value: subTypeName, title: 'Offer Type:' });

  data.push({ value: getTableDate(origins?.createdDate), title: 'Published:' });

  if (disabledAfter) data.push({ value: disabledAfter.toString(), title: 'Disabled After:' });

  data.push({ value: getTableDate(origins?.modifiedDate), title: 'Updated:' });

  return data;
};

export const teeOfferDataTransform = (
  data: TeeOffer | undefined,
  buildOrderForm?: BuildOrderForm,
  slotsFromQuery?: Slots | null,
): Data | undefined => {
  if (!data) return undefined;

  const {
    id, teeOfferInfo, slots, options,
  } = data;
  const { hardwareInfo } = teeOfferInfo || {};
  const field = getFieldBuildOrderdByOfferType(teeOfferInfo?.subType as TeeOfferSubtype, 'TeeOfferSubtype');

  const isAdded = checkAddContent({
    teeOfferId: id,
    field,
    formContent: buildOrderForm,
    slots: slotsFromQuery,
  });

  const subType = getTeeOfferSubTypeName(teeOfferInfo?.subType as TeeOfferSubtype);

  return {
    common: {
      price: getOfferPriceBySlots(slotsFromQuery, slots, options),
      restrictions: [],
      id,
      fieldBuildOrderForm: field,
      selectedSlots: slotsFromQuery,
      isAdded,
    },
    left: {
      name: teeOfferInfo.name,
      about: { value: teeOfferInfo.description, type: DescriptionType.html },
      restricted: [],
      type: OfferTypeSdk.TeeOffer,
      slots: getTeeOfferSlots(slots),
      options: getTeeOfferOptions(options),
      configuration: getTEEConfiguration(hardwareInfo),
      subType,
      modelFormats: null,
      defaultSelectedModelFormat: null,
    },
    right: {
      list: prepareTeeOfferRightData(data),
    },
  };
};

export interface UseGetOfferResult {
  formatted?: Data;
  original?: TeeOffer | Offer;
  isTee?: boolean;
}

export const useGetOffer = (id: string, slotsFromQuery?: Slots | null, skip = false): UseGetOfferResult => {
  const { data: dataOfferType } = useGetOfferTypeQuery({ _id: id, mapTo: TIdSource.Blockchain }, {
    skip: !id || skip,
  });
  const offerType = dataOfferType?.offerType;
  const isTee = offerType === OfferType.TeeOffer;
  const buildOrderForm = useAppSelector(buildOrderFormSelector);
  const { data: dataOffer } = useGetOfferQuery({ _id: id, mapTo: TIdSource.Blockchain }, {
    skip: !id || !offerType || isTee || skip,
  });
  const { data: dataTeeOffer } = useGetTeeOfferQuery({ _id: id, mapTo: TIdSource.Blockchain }, {
    skip: !id || !offerType || !isTee || skip,
  });

  const teeOffer = dataTeeOffer?.teeOffer as TeeOffer;
  const offer = dataOffer?.offer;

  const { description } = useGetDescription(offer?.offerInfo?.description);

  const formatted = useMemo(() => {
    try {
      if (teeOffer) {
        return teeOfferDataTransform(teeOffer, buildOrderForm, slotsFromQuery);
      }
      if (offer) {
        return offerDataTransform(offer, buildOrderForm, description);
      }
      return undefined;
    } catch (error) {
      console.error('Error prepare offer data', error);
      return undefined;
    }
  }, [teeOffer, offer, buildOrderForm, slotsFromQuery, description]);
  return {
    formatted,
    original: teeOffer || offer,
    isTee,
  };
};