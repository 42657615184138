import { File } from 'lib/features/spProvider/types';
import { useGetFileQuery } from 'lib/features/secretKeeper/api';
import { useAuth } from 'hooks/auth/useAuth';

export interface UseSecretKeeperFile {
  data?: File;
  loading: boolean;
  refetch: () => void;
}

export const useSecretKeeperFile = (fileId?: string | null): UseSecretKeeperFile => {
  const { isUserConnected, isProviderSecretKeeper } = useAuth();
  const response = useGetFileQuery(
    fileId as string,
    { skip: !isUserConnected || !fileId || !isProviderSecretKeeper, refetchOnMountOrArgChange: true },
  );

  return {
    data: response.data,
    loading: response.isLoading,
    refetch: response.refetch,
  };
};