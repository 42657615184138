import merge from 'lodash.merge';
import { useCallback } from 'react';
import {
  TOfferType, ValueOfferSubtype, OfferFilter, PipelineType, SortDir,
} from 'generated/types';
import { OfferAttributes } from '@super-protocol/dto-js';
import { ExtendedFormContent, FieldsBuildOrderForm } from 'lib/features/createOrderV2/types';
import { getContentPipelineTypesModelsAndDatasets } from 'lib/features/createOrderV2/helpers';
import { getReversedObj } from 'utils';
import { getSlotFromOffer } from 'utils/slots';

export type GetPreparedFilterEngineProps = Pick<ExtendedFormContent, FieldsBuildOrderForm.model | FieldsBuildOrderForm.datasets>;
export type GetSortingProps = Pick<ExtendedFormContent, FieldsBuildOrderForm.model | FieldsBuildOrderForm.datasets>;

const defaultFilter: OfferFilter = {
  offerType: TOfferType.Solution,
  subType: ValueOfferSubtype.ValueSubtypeEngine,
  inactive: false,
  enabled: true,
};

export const useGetEngineFilters = () => {
  const getSorting = useCallback((props: GetSortingProps) => {
    const { model } = props;
    const slot = getSlotFromOffer(model?.offer, model?.slots?.slot?.id);
    return [
      { sortBy: slot?.info?.vram ? 'slots.info.gpuCores' : 'slots.info.cpuCores', sortDir: SortDir.Desc },
      { sortBy: 'origins.modifiedDate', sortDir: SortDir.Desc },
    ];
  }, []);

  const getFilters = useCallback((props: GetPreparedFilterEngineProps): OfferFilter => {
    const { models, datasets } = getContentPipelineTypesModelsAndDatasets(props);
    const reversedPipelineTypes = getReversedObj<PipelineType, OfferAttributes.PipelineType>(OfferAttributes.PipelineType);

    if (!models?.length && !datasets?.length) {
      return defaultFilter;
    }

    const modelAttributes = models?.length
      ? {
        models: {
          task: {
            pipelineType: reversedPipelineTypes[models[0]],
          },
        },
      }
      : {};

    const datasetAttributes = datasets?.length
      ? {
        datasets: {
          tasks: {
            pipelineType: datasets.map((dataset) => reversedPipelineTypes[dataset]),
          },
        },
      }
      : {};

    return merge({}, defaultFilter, {
      configuration: {
        attributes: {
          ...modelAttributes,
          ...datasetAttributes,
        },
      },
    });
  }, []);

  return { getFilters, getSorting };
};