import { memo, useMemo } from 'react';
import cn from 'classnames';
import SelectCore from 'react-select';

import { Theme } from 'uikitv2/common/types';
import { Box } from 'uikitv2/Box';
import { CustomOption, DropdownIndicator, SingleValue } from './Extentions';
import classes from './Select.module.scss';
import { SelectUiProps, Option } from './types';
import { getCustomStyles } from './helpers';

export const SelectUi = memo<SelectUiProps>(({
  options,
  classNameWrap,
  classNameIcon,
  placeholder = '',
  theme = Theme.dark,
  name,
  onChange,
  defaultValue,
  isMulti,
  id,
  label,
  description,
  classNameLabel,
  styles = {},
  error,
  optionsError,
  disabled,
  value,
  maxMenuHeight,
}) => {
  const customStyles = useMemo(() => getCustomStyles(styles, theme, error), [theme, styles, error]);

  return (
    <Box direction="column" className={cn(classes['select-wrap'], classNameWrap)}>
      {label && (
        <Box className={cn(classes['select-label'], classNameLabel)}>
          {label}
        </Box>
      )}
      {description && (
        <Box className={cn(classes.description)}>
          {description}
        </Box>
      )}
      <SelectCore<Option | string | number, boolean>
        value={value}
        options={options}
        id={id}
        placeholder={placeholder}
        components={{
          DropdownIndicator: (props) => DropdownIndicator(props, classNameIcon),
          Option: (props) => CustomOption(props, optionsError),
          SingleValue: (props) => SingleValue(props, optionsError),
        }}
        styles={customStyles}
        onChange={onChange}
        name={name}
        isMulti={isMulti}
        defaultValue={defaultValue}
        instanceId={id}
        isDisabled={disabled}
        isSearchable={false}
        maxMenuHeight={maxMenuHeight}
      />
    </Box>
  );
});

export default SelectUi;
