import { memo } from 'react';
import cn from 'classnames';
import { Icon } from 'uikitv2/Icon';
import { Button } from 'uikitv2/Buttons/Button';
import { ButtonWithIconProps } from './types';
import classes from './ButtonWithIcon.module.scss';

export const ButtonWithIcon = memo<ButtonWithIconProps>(({
  className, width = 9, iconName = 'copy', height, classNameIcon, variant = 'transparent', ...props
}) => {
  return (
    <Button
      variant={variant}
      className={cn(classes.wrap, className)}
      {...props}
    >
      <Icon
        width={width}
        height={height || width}
        name={iconName}
        className={cn(classes.icon, classNameIcon)}
      />
    </Button>
  );
});
