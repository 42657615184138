import { memo, FC } from 'react';
import { Box } from 'uikitv2/Box';
import { HtmlBox } from 'uikitv2/HtmlBox';
import { MarkdownComponent } from 'uikitv2/MarkdownComponent/MarkdownComponent';
import classes from './About.module.scss';
import { DescriptionType, OfferAboutProps } from './types';
import { Configuration } from './Configuration';

export const OfferAbout: FC<OfferAboutProps> = memo(({ description, configuration, titleConfiguration }) => {
  const { value, type } = description || {};
  const { slots, options } = configuration || {};
  if (!value) return null;

  if (type === DescriptionType.html) {
    return (
      <Box className="column">
        <HtmlBox text={value} ellipsis={false} className={classes.about} />
        <Configuration slots={slots} options={options} title={titleConfiguration} />
      </Box>
    );
  }

  if (type === DescriptionType.md) {
    return (
      <Box className={classes.content}>
        <Box className={classes.wrapper} direction="column">
          <MarkdownComponent>
            {value}
          </MarkdownComponent>
        </Box>
      </Box>
    );
  }

  return null;
});
