import { Slots } from 'lib/features/createOrderV2/types';
import { useGetOffer } from './useGetOffer';
import { useGetFile } from './useGetFile';
import { File } from 'hooks/files/types';
import { Offer, TeeOffer } from 'generated/types';
import { Data } from '../types';

export const useGetOfferOrFile = (
  id: string,
  slotsFromQuery?: Slots | null,
  isDraft = false
): { formatted?: Data; original?: File | Offer | TeeOffer | null; isTee?: boolean } => {
  const offer = useGetOffer(id, slotsFromQuery, isDraft);
  const file = useGetFile(id, !isDraft);

  return isDraft ? file : offer;
};
