import { useCallback, useMemo } from 'react';

import { Box } from 'uikitv2/Box';
import { Tabs } from 'uikitv2/Tabs';
import { VerifiedTooltip } from 'common/components/VerifiedTooltip';
import { OfferAbout } from 'components/OfferAbout';
import { ImageLink } from 'common/components/Title/ImageLink';
import { documentation } from 'common/links';
import classes from './Left.module.scss';
import { Restricted } from './Restricted';
import { Pricing } from './Pricing';
import { LeftComponentProps, OfferTabs } from './types';
import { getTabList, getInitialTab, titleConfiguration } from './helpers';

export const Left = ({
  data,
  selectedSlots,
  loading,
  isCanUpdateSlot,
  isShowCounter,
  isTee,
  tab,
  canGroupByCPUAndGPU,
  onAddSlot,
  onDeleteSlot,
  onChangeTab,
  onDeploySlot,
  isVerified,
}: LeftComponentProps) => {
  const {
    name, about, restricted = [], configuration, type, slots, options, subType, modelFormats, defaultSelectedModelFormat,
  } = data || {};
  const list = useMemo(
    () => getTabList(!restricted.length ? [OfferTabs.restricted] : [], isTee),
    [isTee, restricted],
  );
  const activeTab = useMemo(() => getInitialTab(list, tab), [tab, list]);
  const onClick = useCallback((val: string) => {
    onChangeTab(val as OfferTabs);
  }, [onChangeTab]);

  return (
    <Box direction="column" className={classes.content}>
      <Box className={classes.title} alignItems="center">
        {name}
        {isVerified && <VerifiedTooltip />}
      </Box>
      <Box className={classes['documentation-wrapper']}>
        <Tabs {...{
          list,
          classNameItem: classes.item,
          classNameWrap: classes.wrap,
          active: activeTab,
          onClick,
          alignLeft: true,
        }}
        />
        <ImageLink href={documentation.offer} className={classes.documentation} />
      </Box>
      {activeTab === OfferTabs.about && (
        <OfferAbout description={about} configuration={configuration} titleConfiguration={titleConfiguration} />
      )}
      {activeTab === OfferTabs.pricing && (
        <Pricing {...{
          type,
          defaultSelectedModelFormat,
          modelFormats,
          subType,
          slots,
          options,
          selectedSlots,
          loading,
          onDeleteSlot,
          onDeploySlot,
          onAddSlot,
          isCanUpdateSlot,
          isShowCounter,
          canGroupByCPUAndGPU,
        }}
        />
      )}
      {activeTab === OfferTabs.restricted && (
        <Restricted ids={restricted} />
      )}
    </Box>
  );
};
