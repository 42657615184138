import { useCallback, useState } from 'react';
import {
  Offer,
  TeeOffer,
  TeeOffersAndSLots,
  SortDir,
} from 'generated/types';
import { useAppSelector } from 'lib/hooks';
import { useRouter } from 'next/navigation';
import { useNotification } from 'hooks/useNotification';
import { useLazyGetMatchingTeeSlotsQuery } from 'lib/features/teeOffers';
import { useLazyGetOffersQuery } from 'lib/features/offers';
import { validateRTKResponse } from 'lib/features/helpers';
import { formContentSelector } from 'lib/features/createOrderV2/selectors';
import { MATCH_SLOTS_DEFAULT_SORT } from 'hooks/useGetFirstMatchingTeeConfiguration';
import { useGetEngineFilters } from 'lib/features/createOrderV2/hooks/useGetEngineFilters';
import { getSlotFromOffer } from 'utils/slots';
import { useGetComputeFilters } from './useGetComputeFilters';
import { useContent, AddContentProps } from './useContent';
import { getEngineConfiguration, getSlotsFromTeeOffersAndSLots } from '../helpers';
import {
  ExtendedDataset,
  ExtendedFormContent,
  FieldsBuildOrderForm,
  FormContentKey,
} from '../types';

export type QuickAddContentProps = AddContentProps;

export type QuickDeployProps = QuickAddContentProps;

export type QuickAddModelProps = QuickAddContentProps;

export type QuickAddEngineProps = QuickAddContentProps;

export type QuickAddComputeProps = QuickAddContentProps;

export interface QuickDeleteContentProps {
  field?: FormContentKey | null;
}

export const useQuickDeploy = (extendedFormContent?: ExtendedFormContent) => {
  const router = useRouter();
  const { showError } = useNotification();
  const [loading, setLoading] = useState(false);
  const {
    addOffer, resetContent, addContent, loading: loadingContent,
  } = useContent();
  const { getFilters: getComputeFilters } = useGetComputeFilters();
  const { getFilters: getEngineFilters, getSorting: getEngineSorting } = useGetEngineFilters();
  const [getOffers] = useLazyGetOffersQuery();
  const [getMatchingTeeSlots] = useLazyGetMatchingTeeSlotsQuery();
  const formContent = useAppSelector(formContentSelector);

  const getFirstMathchingTeeAndSlots = useCallback(async (
    extendedFormContent: ExtendedFormContent,
  ): Promise<TeeOffersAndSLots | null> => {
    const computeFilters = await getComputeFilters(extendedFormContent);
    const matchinTeeSlots = validateRTKResponse(
      await getMatchingTeeSlots({ filter: computeFilters, pagination: { sort: MATCH_SLOTS_DEFAULT_SORT, first: 1 } }),
      { path: 'message' },
    );
    return matchinTeeSlots?.data?.result?.page?.edges?.[0]?.node as TeeOffersAndSLots || null;
  }, [getComputeFilters, getMatchingTeeSlots]);

  const prepareEngine = useCallback(async (
    props: Pick<ExtendedFormContent, FieldsBuildOrderForm.model | FieldsBuildOrderForm.datasets>,
  ) => {
    const offersResponse = validateRTKResponse(
      await getOffers({
        pagination: {
          sort: getEngineSorting(props),
          first: 1,
        },
        filter: getEngineFilters(props),
      }),
      { path: 'message' },
    );
    return ((offersResponse?.data?.result?.page?.edges || []).map((edge) => edge?.node as Offer) || [])?.[0] || null;
  }, [getOffers, getEngineFilters, getEngineSorting]);

  const updateEngine = useCallback(async (
    props: Pick<ExtendedFormContent, FieldsBuildOrderForm.model | FieldsBuildOrderForm.datasets>,
  ) => {
    const engineOffer = await prepareEngine(props);
    if (!engineOffer) throw new Error('Engine not found');
    return addOffer({
      value: engineOffer.id,
      field: FieldsBuildOrderForm.engine,
      data: engineOffer,
      configuration: getEngineConfiguration(engineOffer.configuration?.arguments),
    });
  }, [prepareEngine, addOffer]);

  const prepareCompute = useCallback(async (extendedFormContent: ExtendedFormContent) => {
    return getFirstMathchingTeeAndSlots(extendedFormContent);
  }, [getFirstMathchingTeeAndSlots]);

  const updateCompute = useCallback(async (extendedFormContent: ExtendedFormContent) => {
    const teeOfferAndSlot = await prepareCompute(extendedFormContent);
    if (!teeOfferAndSlot) throw new Error('Compute not found');
    const { teeOffer } = teeOfferAndSlot;
    if (!teeOffer) throw new Error('Offer in compute not found');
    return addOffer({
      value: teeOffer.id,
      data: teeOffer as TeeOffer,
      slots: getSlotsFromTeeOffersAndSLots(teeOfferAndSlot),
      field: FieldsBuildOrderForm.compute,
    });
  }, [addOffer, prepareCompute]);

  const quickAddModel = useCallback(async (props: QuickAddModelProps) => {
    const datasets: ExtendedDataset[] = [];
    const model = await addContent(props);
    resetContent([FieldsBuildOrderForm.engine, FieldsBuildOrderForm.compute]);
    const engine = await updateEngine({ model, datasets });
    await updateCompute({ model, datasets, engine });
  }, [addContent, updateEngine, updateCompute, resetContent]);

  const quickAddEngine = useCallback(async (props: QuickAddEngineProps) => {
    const { model: previosModel } = extendedFormContent || formContent;
    if (!previosModel) throw new Error('Model required');
    const datasets: ExtendedDataset[] = [];
    const model = await addContent({ ...previosModel, field: FieldsBuildOrderForm.model });
    const engine = await addContent(props);
    resetContent([FieldsBuildOrderForm.compute]);
    await updateCompute({ model, datasets, engine });
  }, [addContent, updateCompute, resetContent, extendedFormContent, formContent]);

  const quickAddCompute = useCallback(async (props: QuickAddComputeProps) => {
    const { model: previosModel, engine: previousEngine } = extendedFormContent || formContent;
    if (!previosModel) throw new Error('Model required');
    if (!previousEngine) throw new Error('Engine required');
    await addContent({ ...previosModel, field: FieldsBuildOrderForm.model });
    await addContent({ ...previousEngine, field: FieldsBuildOrderForm.engine });
    await addContent(props);
  }, [addContent, extendedFormContent, formContent]);

  const quickAddContent = useCallback(async (props: QuickAddContentProps) => {
    const { field } = props;
    if (!field) throw new Error('Quick add: field required');

    switch (field) {
      case FieldsBuildOrderForm.model:
        return quickAddModel(props);
      case FieldsBuildOrderForm.engine:
        return quickAddEngine(props);
      case FieldsBuildOrderForm.compute:
        return quickAddCompute(props);
      default:
        break;
    }
  }, [quickAddModel, quickAddEngine, quickAddCompute]);

  const quickDeleteContent = useCallback((props: QuickDeleteContentProps) => {
    const { field } = props;
    switch (field) {
      case FieldsBuildOrderForm.model:
      case FieldsBuildOrderForm.datasets:
        return resetContent([
          FieldsBuildOrderForm.model,
          FieldsBuildOrderForm.datasets,
          FieldsBuildOrderForm.engine,
          FieldsBuildOrderForm.compute,
        ]);
      case FieldsBuildOrderForm.engine:
        return resetContent([
          FieldsBuildOrderForm.engine,
          FieldsBuildOrderForm.compute,
        ]);
      case FieldsBuildOrderForm.compute:
        return resetContent([
          FieldsBuildOrderForm.compute,
        ]);
      default:
        break;
    }
  }, [resetContent]);

  const quickDeploy = useCallback(async (props: QuickDeployProps) => {
    await quickAddContent(props);
    router.replace('/order-create');
  }, [quickAddContent, router]);

  const quickAddContentCatched = useCallback(async (props: QuickDeployProps) => {
    try {
      setLoading(true);
      await quickAddContent(props);
    } catch (e) {
      showError(e as Error);
    } finally {
      setLoading(false);
    }
  }, [quickAddContent, showError]);

  const quickDeployCatched = useCallback(async (props: QuickDeployProps) => {
    try {
      setLoading(true);
      await quickDeploy(props);
    } catch (e) {
      showError(e as Error);
    } finally {
      setLoading(false);
    }
  }, [quickDeploy, showError]);

  return {
    quickAddContent,
    quickAddContentCatched,
    quickDeploy,
    quickDeployCatched,
    quickDeleteContent,
    loading: loading || loadingContent,
  };
};