import {
  memo, useCallback, MouseEvent, useMemo,
} from 'react';
import cn from 'classnames';
import { SquareButton } from 'uikitv2/Buttons/SquareButton';
import { Button } from 'uikitv2/Buttons/Button';
import { Box } from 'uikitv2/Box';
import { Icon } from 'uikitv2/Icon';
import { useAppSelector } from 'lib/hooks';
import { themeSelector } from 'lib/features/theme';
import { AdderBtnProps } from './types';
import classes from './AdderBtn.module.scss';
import { deleteBtnPropsBySize, addBtnPropsBySize } from './helpers';

export const AdderBtn = memo(({
  isAdded, onAdd, onDelete, disabled, className, dataTestId, size = 'small', variantDeleteBtn = 'black3', onDeploy,
}: AdderBtnProps) => {
  const theme = useAppSelector(themeSelector);

  const onClickDelete = useCallback((e: MouseEvent) => {
    e.stopPropagation();
    onDelete?.();
  }, [onDelete]);

  const onClickAdd = useCallback((e: MouseEvent) => {
    e.stopPropagation();
    onAdd?.();
  }, [onAdd]);

  const onClickDeploy = useCallback((e: MouseEvent) => {
    e.stopPropagation();
    onDeploy?.();
  }, [onDeploy]);

  const getDeleteBtnPropsBySize = useMemo(() => deleteBtnPropsBySize[size] || deleteBtnPropsBySize.medium, [size]);
  const getDAddBtnPropsBySize = useMemo(() => addBtnPropsBySize[size] || addBtnPropsBySize.medium, [size]);

  if (isAdded) {
    if (!onDelete) return null;
    return (
      <SquareButton
        theme={theme}
        className={className}
        onClick={onClickDelete}
        disabled={disabled}
        size={size}
        variant={variantDeleteBtn}
        data-testid={dataTestId ? `button-${dataTestId}-trash` : ''}
      >
        <Icon
          name={getDeleteBtnPropsBySize.icon}
          className={classes.icon}
          width={getDeleteBtnPropsBySize.width}
          height={getDeleteBtnPropsBySize.height}
        />
      </SquareButton>
    );
  }

  return (
    <Box className={classes.btns}>
      {!!onDeploy && (
        <Button
          theme={theme}
          className={cn(classes['quick-deploy'], className)}
          onClick={onClickDeploy}
          disabled={disabled}
          data-testid={dataTestId ? `button-quick-deploy-${dataTestId}-plus` : ''}
        >
          <>
            <Icon className={classes['quick-deploy__icon']} name="lightning" fill="none" />
            Deploy
          </>
        </Button>
      )}
      {!!onAdd && (
        <SquareButton
          theme={theme}
          className={className}
          onClick={onClickAdd}
          disabled={disabled}
          size={size}
          data-testid={dataTestId ? `button-${dataTestId}-plus` : ''}
        >
          <Icon name={getDAddBtnPropsBySize.icon} width={getDAddBtnPropsBySize.width} height={getDAddBtnPropsBySize.height} />
        </SquareButton>
      )}
    </Box>
  );
});

export default AdderBtn;