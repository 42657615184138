import {
  OfferConfiguration, TeeOfferFilter,
} from 'generated/types';
import { isNumber } from 'utils';

export const getPreparedRange = (value?: number | null): [number] | [] => {
  return [typeof value === 'number' ? value : 0];
};

export const useGetTeeOfferFilterFromOfferConfiguration = () => {
  const getFilters = (filters?: OfferConfiguration): TeeOfferFilter => {
    const {
      externalPort,
      traffic,
      bandwidth,
      ram,
      diskUsage,
      cpuCores,
      gpuCores,
      vram = 0,
      minTimeMinutes,
    } = filters || {};

    return {
      externalPort,
      ...(isNumber(cpuCores) ? { cpuCores: getPreparedRange(cpuCores) } : {}),
      ...(isNumber(gpuCores) ? { gpuCores: getPreparedRange(gpuCores) } : {}),
      ...(isNumber(ram) ? { ram: getPreparedRange(ram) } : {}),
      ...(isNumber(vram) ? { vram: getPreparedRange(vram) } : {}),
      ...(isNumber(traffic) ? { traffic } : {}),
      ...(isNumber(diskUsage) ? { diskUsage: getPreparedRange(diskUsage) } : {}),
      ...(isNumber(bandwidth) ? { bandwidth } : {}),
      ...(isNumber(minTimeMinutes) ? { usageMinutes: minTimeMinutes } : {}),
    };
  };

  return { getFilters };
};