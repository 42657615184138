import type { ValueOfferSubtype } from '@super-protocol/sdk-js';
import { OfferSlot } from 'generated/types';
import {
  getFixedDeposit,
  offerSubTypeName,
} from 'utils/sdk/utils';
import { TransformedOfferSlot } from 'lib/features/offers/types';
import {
  convertSlotInfo, convertOptionData, convertSlotUsageInfo, priceTypeMap,
  getSortedSlots,
} from 'utils/slots';
import { TOKEN_NAME } from 'common/constants';
import { Slots } from 'lib/features/createOrderV2/types';
import { SourceTypesUrls, SlotType } from './types';

export const getSourceType = (url?: string): string | null => {
  if (!url) return null;
  return Object.entries(SourceTypesUrls).find(([key]) => url.startsWith(key))?.[1] || null;
};

export const getSlotType = (subType?: ValueOfferSubtype, slotType?: SlotType): string => {
  switch (slotType) {
    case SlotType.CPU:
      return `Deploy ${offerSubTypeName[subType as string].toLowerCase()} on CPU`;
    case SlotType.GPU:
      return `Deploy ${offerSubTypeName[subType as string].toLowerCase()} on GPU`;
    default:
      return '';
  }
};

export const getOfferSlots = (slots?: OfferSlot[], subType?: ValueOfferSubtype) => {
  return getSortedSlots<OfferSlot>(slots || [])
    .map((slot) => {
      const slotType = slot?.info?.vram > 0 ? SlotType.GPU : SlotType.CPU;
      return {
        id: {
          label: 'slotId',
          value: slot?.id,
          id: slot?.id,
        },
        title: {
          label: priceTypeMap[slot?.usage?.priceType] || '',
          value: `${getFixedDeposit({ deposit: slot?.usage?.price })} ${TOKEN_NAME}`,
          id: slot?.usage?.priceType,
        },
        list: [
          convertSlotInfo({ slotInfo: slot?.info, keys: ['cpuCores', 'ram', 'gpuCores', 'vram'] }),
          [
            ...convertSlotInfo({ slotInfo: slot?.info, keys: ['diskUsage'] }),
            ...convertOptionData({
              optionInfo: slot?.option ? [{ option: slot.option, count: 1 }] : [],
              keys: ['bandwidth', 'traffic', 'externalPort'],
            }),
          ],
          convertSlotUsageInfo({ slotUsage: slot?.usage, keys: ['minTimeMinutes', 'maxTimeMinutes'] }),
        ],
        options: {
          slotType: {
            value: slotType,
            label: getSlotType(subType, slotType),
          },
        },
      };
    });
};

export const getDefaultSelectedModelFormat = (
  modelFormats: Record<string, string[]> | null,
  defaultSlot: Pick<OfferSlot, 'id'> | null,
  selectedSlot: Slots | null,
): string | null => {
  if (!modelFormats) return null;
  const slotId = selectedSlot?.slot?.id || defaultSlot?.id;
  if (!slotId) return null;
  return Object.entries(modelFormats).find(([, ids]) => ids.includes(slotId))?.[0] || null;
};

export const prepareModelFormats = (slots: TransformedOfferSlot[]): Record<string, string[]> | null => {
  if (!slots?.length) return null;
  return slots
    .map((slot) => {
      const { name, quantization, format } = slot?.transformedMetadata || {};
      return {
        groupId: [name, quantization, format].filter(Boolean).join(' '),
        id: slot.id,
      };
    })
    .reduce((acc, { groupId, id }) => {
      if (groupId) {
        acc[groupId] = [...(acc[groupId] || []), id];
      }
      return acc;
    }, {});
};