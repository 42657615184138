import { Theme } from 'uikit/common/types';
import { Option } from './types';

export const getCustomStyles = (styles: Record<string, any>, theme: Theme, error?: string) => {
  const white = 'var(--white)';
  const black3 = 'var(--black3)';
  const black14 = 'var(--black14)';
  const black27 = 'var(--black27)';
  const black28 = 'var(--black28)';
  const transparent = 'transparent';
  const yellow = 'var(--yellow)';
  const {
    singleValue, container, control, menu, option, indicatorSeparator, placeholder, valueContainer, ...props
  } = styles ?? {};

  return {
    singleValue: (provided: Record<string, any>) => ({
      ...provided,
      color: 'inherit',
      ...(singleValue ? { ...singleValue } : {}),
    }),
    container: (provided: Record<string, any>) => ({
      ...provided,
      maxWidth: '100%',
      boxShadow: 'none',
      ...(container ? { ...container } : {}),
    }),
    control: (provided: Record<string, any>, { menuIsOpen }) => {
      return {
        ...provided,
        minHeight: '40px',
        borderColor: error ? yellow : menuIsOpen ? black14 : black28,
        backgroundColor: menuIsOpen ? black14 : transparent,
        boxShadow: 'none',
        fontSize: '12px',
        borderRadius: '4px',
        color: white,
        '&:hover': {
          borderColor: error ? yellow : menuIsOpen ? black14 : black28,
          backgroundColor: menuIsOpen ? black14 : black27,
        },
        '&:focus': {
          borderColor: error ? yellow : black14,
        },
        ...(control ? { ...control } : {}),
      };
    },
    valueContainer: (provided: Record<string, any>) => ({
      ...provided,
      paddingLeft: '12px',
      paddingRight: '0',
      ...(valueContainer ? { ...valueContainer } : {}),
    }),
    menu: (provided: Record<string, any>) => ({
      ...provided,
      margin: 0,
      borderRadius: 0,
      backgroundColor: black14,
      ...(menu ? { ...menu } : {}),
    }),
    option: (provided: Record<string, any>) => ({
      ...provided,
      color: 'inherit',
      backgroundColor: black14,
      fontSize: '12px',
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: black3,
      },
      '&:focus': {
        backgroundColor: black14,
      },
      ...(option ? { ...option } : {}),
    }),
    indicatorSeparator: () => ({
      display: 'none',
      ...(indicatorSeparator ? { ...indicatorSeparator } : {}),
    }),
    placeholder: (provided: Record<string, any>) => ({
      ...provided,
      color: white,
      fontSize: '12px',
      ...(placeholder ? { ...placeholder } : {}),
    }),
    ...props,
  };
};

export const getDefaultValue = (options?: Option[], value?: Option | Option[] | string | number) => {
  if (typeof value !== 'object') {
    return (options || []).find((item) => item.value === value);
  }
  return value;
};
