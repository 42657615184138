import { useField } from 'formik';
import { useCallback, useMemo } from 'react';
import { Option, ValueType } from '../types';

export interface UseSelectFormikProps {
  name?: string;
  onChange?: Function;
  error?: string;
  checkTouched?: boolean;
}

export interface UseSelectFormikResult {
  handleOnChange: (valueProps: Option, typeofValue: ValueType) => void;
  value?: Option | Option[];
  isInvalid: boolean;
  error?: string;
}

export const useSelectFormik = ({
  name,
  error: errorProp,
  onChange = () => {},
  checkTouched = true,
}: UseSelectFormikProps): UseSelectFormikResult => {
  const [, { value, error, touched }, { setValue }] = useField(name as string);
  const isInvalid: boolean = useMemo(
    () => !!((error || errorProp) && (!checkTouched || touched)),
    [error, touched, checkTouched, errorProp],
  );
  const handleOnChange = useCallback((valueProps: Option, typeofValue: ValueType) => {
    if (typeofValue !== ValueType.object && typeof valueProps === 'object') {
      setValue(valueProps.value);
    } else {
      setValue(valueProps);
    }
    onChange(valueProps);
  }, [onChange, setValue]);

  return {
    handleOnChange,
    value,
    isInvalid,
    error: errorProp || error,
  };
};
