import { memo, useMemo } from 'react';

import { SelectUi } from './SelectUi';
import { Option, SelectFormikProps, ValueType } from './types';
import { useSelectFormik } from './hooks/useSelectFormik';
import { getDefaultValue } from './helpers';

export const SelectFormik = memo(({
  name,
  onChange,
  checkTouched,
  error: errorPropProp,
  defaultValue,
  options,
  valueType = ValueType.object,
  ...props
}: SelectFormikProps) => {
  const { value, handleOnChange, error } = useSelectFormik({
    name,
    error: errorPropProp,
    onChange,
    checkTouched,
  });

  const defaultOption = useMemo(() => getDefaultValue(options, value), [options, value]);

  return (
    <SelectUi
      {...props}
      name={name}
      value={defaultOption}
      error={error}
      defaultValue={defaultOption || defaultValue}
      options={options}
      onChange={(valueProps) => handleOnChange(valueProps as Option, valueType) as any}
    />
  );
});
