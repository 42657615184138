import { memo } from 'react';

import { Icon } from 'uikitv2/Icon';
import { Tooltip } from 'uikitv2/Tooltip';
import { text } from './helpers';
import classes from './VerifiedTooltip.module.scss';

export const VerifiedTooltip = memo(() => {
  return (
    <Tooltip
      tooltip={text}
      classNamePopover={classes.popover}
      placement="top"
    >
      <Icon name="verification" fill="none" className={classes.icon} />
    </Tooltip>
  );
});
