import { Button } from 'uikitv2/Buttons';
import classes from './Right.module.scss';
import { RighListComponent } from './types';

export const labelLine = 'Offer added to Order';

export const getValueText = (v: string | string[]) => {
  if (Array.isArray(v)) {
    return v.map((el, idx) => (
      <span className={classes['price-text']} key={idx}>{el}</span>
    ));
  }
  return <span className={classes['price-text']}>{v}</span>;
};

export const getValueLink = (v: string | string[], label: string) => {
  if (Array.isArray(v)) {
    return v.map((el, idx) => (
      <Button href={el} variant="blue-link" rel="noopener noreferrer" target="_blank" key={idx}>{label}</Button>
    ));
  }
  return <Button variant="blue-link" rel="noopener noreferrer" target="_blank" href={v}>{label}</Button>;
};

export const renderComponent = (value: string | string[], data: any, component = RighListComponent.text) => {
  switch (component) {
    case RighListComponent.text:
      return getValueText(value);
    case RighListComponent.link:
      return getValueLink(value, data);
    default:
      return null;
  }
};