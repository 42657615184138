import { FC } from 'react';
import cn from 'classnames';

import classes from './ButtonText.module.scss';
import { ButtonTextProps } from './types';

export const ButtonText: FC<ButtonTextProps> = ({
  children, onClick, className, disabled,
}) => {
  return (
    <button
      disabled={disabled}
      type="button"
      className={cn(classes.content, className)}
      onClick={onClick}
      onKeyDown={(e) => e.key === 'Enter' && onClick()}
      role="link"
    >
      {children}
    </button>
  );
};
