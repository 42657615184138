/* eslint-disable max-len */
import { OfferType } from '@super-protocol/sdk-js';
import { Option } from 'uikitv2/Select/types';
import { FormattedBilletData } from './types';

export const text = {
  [OfferType.Data]: 'Please select the model format and requirements most compatible with your intended usage.',
  [OfferType.TeeOffer]: 'This compute provider is offering the following slots and options.',
  [OfferType.Solution]: 'The provider is offering the following pricing plans for scaling of the solution.',
  [OfferType.Storage]: '',
};

export const getTypeOf = (type: OfferType) => {
  return Object.entries(OfferType).reduce((acc, [k, v]) => ({ ...acc, [v]: k }), {})[type];
};

export const getModelFormatsOptions = (formats?: Record<string, string[]> | null): Option[] => {
  if (!formats) return [];
  return Object.entries(formats).map(([groupId]) => ({ value: groupId, label: groupId }));
};

export const getGroupedSlots = (list?: FormattedBilletData[], canGroupByCPUAndGPU = false) => {
  if (!canGroupByCPUAndGPU || !list?.length) return [];
  const grouped = list.reduce((acc, item) => {
    const slotType = item.options?.slotType?.value;
    if (slotType) {
      if (!acc[slotType]) {
        acc[slotType] = { label: item.options?.slotType?.label || '', list: [item] };
      } else {
        acc[slotType].list.push(item);
      }
    }
    return acc;
  }, {} as Record<string, { label: string; list: FormattedBilletData[]; }>);

  return Object.entries(grouped).map(([type, { label, list }]) => ({
    label,
    list,
    value: type,
  }));
};