import { memo } from 'react';
import cn from 'classnames';

import { Theme } from 'uikit/common/types';
import { Box } from 'uikitv2/Box';
import { TabsBtnProps } from './types';
import classes from './TabsBtn.module.scss';

export const TabsBtn = memo(({
  disabled, onClick, className, label, theme = Theme.dark, dataTestId, active, hasUnderline, hasDot,
}: TabsBtnProps) => {
  return (
    <Box alignItems="center">
      <button
        disabled={disabled}
        onClick={onClick}
        className={cn(
          classes.wrap,
          { [classes.underline]: hasUnderline },
          className,
          classes[theme],
          { [classes.disabled]: disabled },
        )}
        data-testid={dataTestId}
        data-active={active}
      >
        <span>{label}</span>
      </button>
      {hasDot && <div className={classes.dot} />}
    </Box>
  );
});
