import { useCallback, useMemo } from 'react';

import { Box } from 'uikit/Box';
import { Offer as OfferType, TeeOffer } from 'generated/types';
import { useQuickDeploy } from 'lib/features/createOrderV2/hooks/useQuickDeploy';
import { FieldsBuildOrderForm, SLOT_COUNT } from 'lib/features/createOrderV2/types';
import { File } from 'hooks/files/types';
import { useGetOfferOrFile } from './hooks/useGetOfferOrFile';
import { OfferProps, Data } from './types';
import { Left } from './Left';
import { Right } from './Right';
import classes from './Offer.module.scss';

export const Offer = ({
  id, slotsFromQuery, tab, onChangeTab, canQuickDeploy: canQuickDeployProp, isDraft,
}: OfferProps) => {
  const {
    quickDeployCatched, loading, quickAddContentCatched, quickDeleteContent,
  } = useQuickDeploy();
  const { original, formatted, isTee } = useGetOfferOrFile(id, slotsFromQuery, isDraft);
  const { right, left, common } = useMemo(() => formatted || {} as Data, [formatted]);
  const onDeleteSlot = useCallback(() => {
    quickDeleteContent({ field: common?.fieldBuildOrderForm });
  }, [quickDeleteContent, common]);

  const onAddSlot = useCallback((slotId: string) => {
    if (!original) return;
    const props = isDraft ? {
      fileId: common?.id,
      field: common?.fieldBuildOrderForm as FieldsBuildOrderForm.model,
      data: original as File,
      slots: { slot: { id: slotId, count: SLOT_COUNT.VALUE_OFFER } }
    } : {
      offerId: common?.id,
      field: common?.fieldBuildOrderForm,
      data: original as OfferType | TeeOffer,
      slots: { slot: { id: slotId, count: SLOT_COUNT.VALUE_OFFER } }
    };
    return quickAddContentCatched(props);
  }, [quickAddContentCatched, common, original, isDraft]);

  const onAddOffer = useCallback(() => {
    if (!original) return;
    const props = isDraft ? {
      fileId: common?.id,
      field: common?.fieldBuildOrderForm,
      data: original as File,
      slots: slotsFromQuery,
    } : {
      offerId: common?.id,
      field: common?.fieldBuildOrderForm,
      data: original as OfferType | TeeOffer,
      slots: slotsFromQuery,
    };
    return quickAddContentCatched(props);
  }, [quickAddContentCatched, common, original, slotsFromQuery, isDraft]);

  const onDelete = useCallback(() => {
    quickDeleteContent({ field: common?.fieldBuildOrderForm });
  }, [quickDeleteContent, common]);

  const onDeploySlot = useCallback((slotId: string) => {
    if (!original) return;
    const props = isDraft ? {
      fileId: common?.id,
      field: common?.fieldBuildOrderForm as FieldsBuildOrderForm.model,
      data: original as File,
      slots: { slot: { id: slotId, count: SLOT_COUNT.VALUE_OFFER } }
    } : {
      offerId: common?.id,
      field: common?.fieldBuildOrderForm,
      data: original as OfferType | TeeOffer,
      slots: { slot: { id: slotId, count: SLOT_COUNT.VALUE_OFFER } }
    };
    return quickDeployCatched(props);
  }, [common, quickDeployCatched, original, isDraft]);

  const canQuickDeploy = useMemo(
    () => canQuickDeployProp && [
      FieldsBuildOrderForm.model,
      FieldsBuildOrderForm.datasets,
    ].includes(common?.fieldBuildOrderForm as FieldsBuildOrderForm),
    [common, canQuickDeployProp],
  );

  const canAddSlot = useMemo(() => {
    return [
      FieldsBuildOrderForm.model,
      FieldsBuildOrderForm.datasets,
      FieldsBuildOrderForm.engine,
    ].includes(common?.fieldBuildOrderForm as FieldsBuildOrderForm);
  }, [common]);

  const canGroupByCPUAndGPU = useMemo(
    () => [FieldsBuildOrderForm.model].includes(common?.fieldBuildOrderForm as FieldsBuildOrderForm),
    [common],
  );

  const isEnabled = useMemo(() => {
    if (!original) return false;
    if ('enabled' in original) {
      return original.enabled;
    }
    return false;
  }, [original]);

  return (
    <Box className={classes.content}>
      <Left {...{
        data: left,
        selectedSlots: common?.selectedSlots,
        loading,
        ...(canQuickDeploy ? { onDeploySlot } : canAddSlot ? { onAddSlot } : {}),
        onDeleteSlot,
        isCanUpdateSlot: !isTee && !isDraft && isEnabled,
        isShowCounter: isTee && common?.isAdded,
        canGroupByCPUAndGPU,
        isTee,
        tab,
        onChangeTab,
        isVerified: !!(original as OfferType)?.verifiedAt,
      }}
      />
      <Right
        {...{
          list: right?.list,
          price: common?.price,
          id,
          loading,
          isAdded: common?.isAdded,
          isShowAddBtn: isTee,
        }}
        onAdd={onAddOffer}
        onDelete={onDelete}
      />
    </Box>
  );
};
