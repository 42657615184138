import { useMemo } from 'react';
import { components } from 'react-select';
import cn from 'classnames';

import { Icon } from 'uikitv2/Icon';
import classes from './Extentions.module.scss';
import { OptionError } from '../types';

export const DropdownIndicator = (props, classNameIcon) => {
  const { isFocused } = props;
  return (
    <components.DropdownIndicator {...props}>
      <Icon name="triangle" className={cn(classes.icon, { [classes['icon-focused']]: isFocused }, classNameIcon)} />
    </components.DropdownIndicator>
  );
};

export const CustomOption = (props, optionsError?: OptionError[]) => {
  const { data, children } = props;
  const hasError = useMemo(() => optionsError?.find((item) => item.value === data.value), [data.value, optionsError]);
  return (
    <components.Option {...props} className={classes.option}>
      {children}
      {hasError && <div className={classes.dot} />}
    </components.Option>
  );
};

export const SingleValue = (props, optionsError?: OptionError[]) => {
  const { data, children } = props;
  const hasError = useMemo(() => optionsError?.find((item) => item.value === data.value), [data.value, optionsError]);
  return (
    <components.SingleValue {...props} className={classes['single-value']}>
      {children}
      {hasError && <div className={classes.dot} />}
    </components.SingleValue>
  );
};
