import cn from 'classnames';

import { Box } from 'uikitv2/Box';
import { EditRowButtonProps } from './types';
import classes from './EditRowButton.module.scss';

export const EditRowButton = ({ className }: EditRowButtonProps) => {
  return (
    <Box className={cn(classes.dots, className)} alignItems="center" justifyContent="center" direction="column">...</Box>
  );
};
