export enum DescriptionType {
  md = 'md',
  html = 'html'
}

export interface Description { value: string; type: DescriptionType; }

export interface OfferAboutProps {
  description?: Description;
  configuration?: {
    slots?: { label: string; value: string; id: string; }[];
    options?: { label: string; value: string; id: string; }[];
  };
  titleConfiguration?: string;
}