import {
  memo, useCallback, useEffect, useMemo, useRef, useState,
} from 'react';
import { OfferType } from '@super-protocol/sdk-js';

import { Box } from 'uikitv2/Box';
import { SelectUi } from 'uikitv2/Select';
import { Option } from 'uikitv2/Select/types';
import { Billet } from './Billet';
import { FormattedBilletData, PricingProps } from './types';
import {
  text, getTypeOf, getModelFormatsOptions, getGroupedSlots,
} from './helpers';
import classes from './Pricing.module.scss';

export const Pricing = memo(({
  type,
  subType,
  slots,
  options: optionsData,
  selectedSlots,
  onAddSlot,
  onDeleteSlot,
  onDeploySlot,
  isCanUpdateSlot,
  isShowCounter,
  loading,
  modelFormats,
  defaultSelectedModelFormat,
  canGroupByCPUAndGPU,
}: PricingProps) => {
  const params = useMemo(() => ({
    text: type ? text[type] : '',
    title: type ? type === OfferType.TeeOffer ? 'Slots' : `${getTypeOf(type)} Requirements` : '',
  }), [type]);
  const isAlreadyInitModelFormatRef = useRef(false);
  const [modelFormat, setModelFormat] = useState<Option>();
  const title = useMemo(() => (subType ? `${subType} Requirements` : ''), [subType]);
  const { slot, options } = useMemo(() => selectedSlots || {}, [selectedSlots]);
  const modelFormatsOptions = useMemo(() => getModelFormatsOptions(modelFormats), [modelFormats]);
  const slotsByModelFormats = useMemo(
    () => (modelFormat?.value && modelFormats?.[modelFormat.value] ? modelFormats[modelFormat.value] : []),
    [modelFormats, modelFormat],
  );

  const filteredSlots = useMemo(() => {
    if (!slotsByModelFormats?.length) return slots || [];
    return (slots || []).filter(({ id }) => slotsByModelFormats.includes(id?.value));
  }, [slots, slotsByModelFormats]);

  const groupedSlots = useMemo(() => getGroupedSlots(filteredSlots, canGroupByCPUAndGPU), [canGroupByCPUAndGPU, filteredSlots]);

  useEffect(() => {
    if (defaultSelectedModelFormat && !isAlreadyInitModelFormatRef.current) {
      const modelFormat = modelFormatsOptions.find(({ value }) => value === defaultSelectedModelFormat);
      if (modelFormat) {
        isAlreadyInitModelFormatRef.current = true;
      }
      setModelFormat(modelFormat);
    }
  }, [defaultSelectedModelFormat, modelFormatsOptions]);

  const onChangeModelFormat = useCallback((val) => {
    setModelFormat(val);
  }, []);

  const renderSlot = useCallback((item: FormattedBilletData, index: number) => {
    const isSelected = slot?.id === item?.id?.value;
    return (
      <Billet
        data={item}
        isSelected={isSelected}
        key={item.id.id || index}
        value={isSelected ? slot?.count : 0}
        onDeploy={onDeploySlot}
        onAdd={onAddSlot}
        onDelete={onDeleteSlot}
        isCanUpdate={isCanUpdateSlot}
        isShowCounter={isShowCounter}
        loading={loading}
      />
    );
  }, [slot, onDeploySlot, onAddSlot, onDeleteSlot, isShowCounter, isCanUpdateSlot, loading]);

  return (
    <Box direction="column" className={classes.content}>
      {!!params.text && <div className={classes.text}>{params.text}</div>}
      {!!modelFormatsOptions?.length && (
        <SelectUi
          options={modelFormatsOptions}
          onChange={onChangeModelFormat}
          value={modelFormat}
          label={`${subType} Format`}
          placeholder=""
          classNameWrap={classes.selectWrap}
        />
      )}
      {!!params.title && !canGroupByCPUAndGPU && !!filteredSlots.length && (
        <div className={classes.title}>{type === OfferType.TeeOffer ? params.title : title}</div>
      )}
      {groupedSlots.length ? (
        <Box direction="column">
          {groupedSlots.map(({ label, list, value }) => (
            <Box key={value} className={classes.billets} direction="column">
              <Box>{label}</Box>
              {list.map(renderSlot)}
            </Box>
          ))}
        </Box>
      ) : !!filteredSlots.length && (
        <Box className={classes.billets} direction="column">
          {filteredSlots.map(renderSlot)}
        </Box>
      )}
      {!!optionsData?.length && (
        <>
          <div className={classes.title}>Options</div>
          <Box className={classes.billets} direction="column">
            {optionsData.map((item) => {
              const id = item?.id?.value;
              const optionCount = options?.find(({ id: optionId }) => id === optionId)?.count || 0;
              return (
                <Billet
                  data={item}
                  key={item.id.id}
                  value={optionCount}
                  isShowCounter={isShowCounter}
                  isCanUpdate={false}
                  loading={loading}
                />
              );
            })}
          </Box>
        </>
      )}
    </Box>
  );
});
