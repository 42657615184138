import { useCallback, useReducer } from 'react';
import { v4 as uuid } from 'uuid';

import { File } from 'hooks/files/types';
import { useAppDispatch, useAppSelector } from 'lib/hooks';
import { useLazyGetOfferQuery } from 'lib/features/offers';
import { useLazyGetTeeOfferQuery } from 'lib/features/teeOffers';
import { useNotification } from 'hooks/useNotification';
import { useLazyFile } from 'hooks/files/useLazyFile/useLazyFile';
import { useOfferModal, OfferTabs } from 'hooks/useOfferModal';
import {
  TeeOffer, Offer, TIdSource,
} from 'generated/types';
import { buildOrderFormSelector } from 'lib/features/createOrderV2/selectors';
import { validateRTKResponse } from 'lib/features/helpers';
import {
  FieldsBuildOrderForm,
  Slots,
  FormContentKey,
  SLOT_COUNT,
  AdditionalFormContent,
  Dataset,
  Model,
  Engine,
  Compute,
} from '../types';
import { addContent as addContentAction, deleteContent as deleteContentAction } from '../thunks';
import { getDefaultSlots, checkIsDifferentOfferPriceType } from '../helpers';
import {
  addEngineAdditonalFormContent as addEngineAdditonalFormContentAction,
  resetBuildOrderFormFields as resetBuildOrderFormFieldsAction,
} from '..';

export interface AddFileProps {
  value?: string;
  data?: File | null;
  slots?: Slots | null;
  field?: FormContentKey | null;
}

export interface AddOfferProps {
  value?: string;
  data?: Offer | TeeOffer | null;
  field?: FormContentKey | null;
  slots?: Slots | null;
  configuration?: Record<string, any> | null;
  additionalFormContent?: AdditionalFormContent[] | null;
}

export type AddOfferResult = Dataset | Model | Engine | Compute | null | undefined;

export interface AddOfferWithCheckingProps extends AddOfferProps {}

type AddContentPropsOfferOrFile = Omit<AddOfferProps, 'value'> | Omit<AddFileProps, 'value'>;

export type AddContentProps = {
  offerId?: string;
  fileId?: string;
} & AddContentPropsOfferOrFile;

export interface AddSlotProps {
  data?: Offer | TeeOffer | null;
  offerId: string;
  field?: FormContentKey;
  slotId: string;
  slotCount?: number;
}

export interface DeleteFileProps {
  value?: string;
  field?: FormContentKey | null;
}

export interface DeleteOfferProps {
  value?: string;
  field?: FormContentKey | null;
}

type DeleteContentPropsOfferOrFile = Omit<DeleteOfferProps, 'value'> | Omit<DeleteFileProps, 'value'>;

export type DeleteContentProps = {
  offerId?: string;
  fileId?: string;
  teeOfferId?: string;
} & DeleteContentPropsOfferOrFile;

export interface DeleteSlotProps {
  offerId: string;
  field?: FormContentKey;
}

interface LoadingState {
  addOffer: boolean;
  deleteOffer: boolean;
  addContent: boolean;
  deleteContent: boolean;
  addFile: boolean;
  deleteFile: boolean;
  addSlot: boolean;
  deleteSlot: boolean;
  addOfferWithCheckMultipleSlots: boolean;
  addEngineAdditonalFormContent: boolean;
}

const getInitialLoadingState = (): LoadingState => ({
  addOffer: false,
  deleteOffer: false,
  addContent: false,
  deleteContent: false,
  addFile: false,
  deleteFile: false,
  addSlot: false,
  deleteSlot: false,
  addOfferWithCheckMultipleSlots: false,
  addEngineAdditonalFormContent: false,
});

export const reducerDicts = (
  state: LoadingState,
  action: { type: keyof LoadingState, payload: boolean },
) => ({ ...state, [action.type]: action.payload });

export const useContent = () => {
  const { showError } = useNotification();
  const [loadingState, dispatchLoadingState] = useReducer(reducerDicts, getInitialLoadingState());
  const { openModal } = useOfferModal();
  const { getFile } = useLazyFile();
  const dispatch = useAppDispatch();
  const [getOffer] = useLazyGetOfferQuery();
  const [getTeeOffer] = useLazyGetTeeOfferQuery();
  const buildOrderForm = useAppSelector(buildOrderFormSelector);

  const fetchOfferData = useCallback(async (offerId?: string, field?: FormContentKey): Promise<Offer | TeeOffer | null> => {
    if (!offerId || !field) return null;
    const props = { _id: offerId, mapTo: TIdSource.Blockchain };
    switch (field) {
      case FieldsBuildOrderForm.compute:
        return (await getTeeOffer(props).catch(() => null))?.data?.teeOffer as TeeOffer;
      case FieldsBuildOrderForm.datasets:
      case FieldsBuildOrderForm.engine:
      case FieldsBuildOrderForm.model:
        return (await getOffer(props).catch(() => null))?.data?.offer as Offer;
      default:
        return null;
    }
  }, [getTeeOffer, getOffer]);

  const addOffer = useCallback(async (props: AddOfferProps) => {
    const {
      value, field, slots: slotsProp, data: dataProp, configuration, additionalFormContent,
    } = props || {};
    let data = dataProp;
    if (!field) {
      throw new Error('Form field required');
    }
    if (!value) {
      throw new Error('Offer id required');
    }
    if (!data) {
      data = await fetchOfferData(value, field);
    }
    if (!data) {
      throw new Error('Missing offer information');
    }
    const slots = slotsProp || await getDefaultSlots(data);
    // todo use slots and offerData for checking conflict
    if (!slots) {
      throw new Error('Slot required');
    }
    const content = {
      ...(field === FieldsBuildOrderForm.compute ? { teeOfferId: value } : { offerId: value }),
      slots,
      id: uuid(),
      configuration,
      additionalFormContent,
    };
    dispatch(addContentAction({ field, value: content }));
    return {
      ...content,
      ...(field === FieldsBuildOrderForm.compute ? { teeOffer: data as TeeOffer } : { offer: data as Offer }),
    };
  }, [dispatch, fetchOfferData]);

  const addOfferCatched = useCallback(async (props: AddOfferProps) => {
    try {
      dispatchLoadingState({ type: 'addOffer', payload: true });
      return await addOffer(props);
    } catch (e) {
      showError(e as Error);
    } finally {
      dispatchLoadingState({ type: 'addOffer', payload: false });
    }
  }, [addOffer, showError]);

  const addFile = useCallback(async (props: AddFileProps) => {
    const {
      value, field, data: dataProp,
    } = props || {};
    let data = dataProp;
    if (!field) {
      throw new Error('Content field required');
    }
    if (!value) {
      throw new Error('Content id required');
    }
    if (!data) {
      data = validateRTKResponse<File>(await getFile(value as string))?.data;
    }
    if (!data) {
      throw new Error('Missing content information');
    }
    const content = {
      fileId: value,
      id: uuid(),
    };
    dispatch(addContentAction({ field, value: content }));
    return {
      ...content,
      file: data,
    };
  }, [getFile, dispatch]);

  const addFileCatched = useCallback(async (props: AddFileProps) => {
    try {
      dispatchLoadingState({ type: 'addFile', payload: true });
      return await addFile(props);
    } catch (e) {
      showError(e as Error);
    } finally {
      dispatchLoadingState({ type: 'addFile', payload: false });
    }
  }, [addFile, showError]);

  const addContent = useCallback(async (props: AddContentProps) => {
    const { offerId, fileId, ...otherProps } = props;
    if (offerId) {
      return addOffer({ value: offerId, ...otherProps as AddOfferProps });
    }
    if (fileId) {
      return addFile({ value: fileId, ...otherProps as AddFileProps });
    }
    return null;
  }, [addFile, addOffer]);

  const addContentCatched = useCallback(async (props: AddContentProps) => {
    try {
      dispatchLoadingState({ type: 'addContent', payload: true });
      return await addContent(props);
    } catch (e) {
      showError(e as Error);
    } finally {
      dispatchLoadingState({ type: 'addContent', payload: false });
    }
  }, [addContent, showError]);

  const deleteOffer = useCallback(async (props: DeleteOfferProps) => {
    const {
      value, field,
    } = props || {};
    if (!field) {
      throw new Error('Content field required');
    }
    dispatch(deleteContentAction({ field, offerId: value }));
  }, [dispatch]);

  const deleteOfferCatched = useCallback(async (props: DeleteOfferProps) => {
    try {
      dispatchLoadingState({ type: 'deleteOffer', payload: true });
      return await deleteOffer(props);
    } catch (e) {
      showError(e as Error);
    } finally {
      dispatchLoadingState({ type: 'deleteOffer', payload: false });
    }
  }, [deleteOffer, showError]);

  const deleteFile = useCallback(async (props: DeleteFileProps) => {
    const {
      value, field,
    } = props || {};
    if (!field) {
      throw new Error('Content field required');
    }
    dispatch(deleteContentAction({ field, fileId: value }));
  }, [dispatch]);

  const deleteFileCatched = useCallback(async (props: DeleteFileProps) => {
    try {
      dispatchLoadingState({ type: 'deleteFile', payload: true });
      return await deleteFile(props);
    } catch (e) {
      showError(e as Error);
    } finally {
      dispatchLoadingState({ type: 'deleteFile', payload: false });
    }
  }, [deleteFile, showError]);

  const deleteContent = useCallback(async (props: DeleteContentProps) => {
    const {
      fileId, offerId, teeOfferId, ...otherProps
    } = props || {};
    if (offerId) {
      return deleteOffer({ value: offerId, ...otherProps as DeleteOfferProps });
    }
    if (fileId) {
      return deleteFile({ value: fileId, ...otherProps as DeleteFileProps });
    }
    if (teeOfferId) {
      return deleteOffer({ value: teeOfferId, ...otherProps as DeleteOfferProps });
    }
  }, [deleteOffer, deleteFile]);

  const deleteContentCatched = useCallback(async (props: DeleteContentProps) => {
    try {
      dispatchLoadingState({ type: 'deleteContent', payload: true });
      return await deleteContent(props);
    } catch (e) {
      showError(e as Error);
    } finally {
      dispatchLoadingState({ type: 'deleteContent', payload: false });
    }
  }, [deleteContent, showError]);

  const addSlot = useCallback(async (props: AddSlotProps) => {
    const {
      field, slotId, slotCount = SLOT_COUNT.VALUE_OFFER, offerId, data,
    } = props;
    const newSlot = {
      id: slotId,
      count: slotCount,
    };
    await addOffer({
      value: offerId,
      slots: {
        slot: newSlot,
      },
      data,
      field,
    });
  }, [addOffer]);

  const addSlotCatched = useCallback(async (props: AddSlotProps) => {
    try {
      dispatchLoadingState({ type: 'addSlot', payload: true });
      return await addSlot(props);
    } catch (e) {
      showError(e as Error);
    } finally {
      dispatchLoadingState({ type: 'addSlot', payload: false });
    }
  }, [addSlot, showError]);

  const deleteSlot = useCallback(async (props: DeleteSlotProps) => {
    const { field, offerId } = props || {};
    deleteOffer({ field, value: offerId });
  }, [deleteOffer]);

  const deleteSlotCatched = useCallback(async (props: DeleteSlotProps) => {
    try {
      dispatchLoadingState({ type: 'addSlot', payload: false });
      return await deleteSlot(props);
    } catch (e) {
      showError(e as Error);
    } finally {
      dispatchLoadingState({ type: 'deleteSlot', payload: false });
    }
  }, [deleteSlot, showError]);

  const addOfferWithCheckMultipleSlots = useCallback(async (props: AddOfferWithCheckingProps) => {
    const { data, value, slots } = props || {};
    if (!data || checkIsDifferentOfferPriceType((data as Offer)?.slots)) {
      return openModal(value, slots, OfferTabs.pricing);
    }
    return addOffer(props);
  }, [addOffer, openModal]);

  const addOfferWithCheckMultipleSlotsCatched = useCallback(async (props: AddOfferWithCheckingProps) => {
    try {
      dispatchLoadingState({ type: 'addOfferWithCheckMultipleSlots', payload: true });
      return await addOfferWithCheckMultipleSlots(props);
    } catch (e) {
      showError(e as Error);
    } finally {
      dispatchLoadingState({ type: 'addOfferWithCheckMultipleSlots', payload: false });
    }
  }, [addOfferWithCheckMultipleSlots, showError]);

  const addEngineAdditonalFormContent = useCallback((additionalFormContent: (AdditionalFormContent[]) | null) => {
    dispatch(addEngineAdditonalFormContentAction({ value: additionalFormContent }));
  }, [dispatch]);

  const addEngineAdditonalFormContentCatched = useCallback((additionalFormContent: (AdditionalFormContent[]) | null) => {
    try {
      dispatchLoadingState({ type: 'addEngineAdditonalFormContent', payload: true });
      return addEngineAdditonalFormContent(additionalFormContent);
    } catch (e) {
      showError(e as Error);
    } finally {
      dispatchLoadingState({ type: 'addEngineAdditonalFormContent', payload: false });
    }
  }, [addEngineAdditonalFormContent, showError]);

  const resetContent = useCallback((reset: FieldsBuildOrderForm[]) => {
    dispatch(resetBuildOrderFormFieldsAction({ reset }));
  }, [dispatch]);

  // todo maybe later
  // const addOption = useCallback(async (props: AddOptionProps) => {
  //   const {
  //     field, optionId, optionCount = 1, offerId, data,
  //   } = props;
  //   if (getIsOfferAlreadyAdded(offerId, field, formOffers)) {
  //     dispatch(updateOptionInFormAction({ key: field, value: [optionId, optionCount], offerId }));
  //   } else {
  //     await addOfferToFormCatched({
  //       value: offerId,
  //       slots: {
  //         options: [{ id: optionId, count: optionsCount }],
  //       },
  //       data,
  //       field,
  //     });
  //   }
  // }, [addOfferToFormCatched, dispatch, formOffers]);

  // const deleteOption = useCallback(async (props: DeleteOptionProps) => {
  //   const { field, offerId, optionId } = props;
  //   dispatch(deleteOptionInFormAction({ key: field, offerId, optionId }));
  // }, [dispatch]);

  // const deleteOfferFromForm = useCallback((props: DeleteOfferFromFormProps) => {
  //   const { field, value } = props || {};
  //   dispatch(deleteOfferFromFormAction({ key: field, value }));
  //   dispatch(resetStepsAction());
  // }, [dispatch]);

  return {
    addOfferWithCheckMultipleSlots,
    addContent,
    addOffer,
    addFile,
    deleteOffer,
    deleteFile,
    deleteContent,
    addSlot,
    deleteSlot,
    addOfferWithCheckMultipleSlotsCatched,
    addContentCatched,
    addOfferCatched,
    addFileCatched,
    deleteOfferCatched,
    deleteFileCatched,
    deleteContentCatched,
    addSlotCatched,
    deleteSlotCatched,
    addEngineAdditonalFormContent,
    addEngineAdditonalFormContentCatched,
    resetContent,
    buildOrderForm,
    loadingState,
    loading: Object.values(loadingState).some(Boolean),
  };
};