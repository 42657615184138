import { useMemo } from 'react';
import { ValueOfferSubtype, OfferType } from '@super-protocol/sdk-js';
import { useFile } from 'hooks/files/useFile';
import { Description } from 'components/OfferAbout/types';
import { BuildOrderForm } from 'lib/features/createOrderV2/types';
import { File } from 'hooks/files/types';
import { getPriceTypeBFFFromPriceTypeSDK, getValueOfferSubTypeName } from 'utils/sdk/utils';
import {
  getFieldBuildOrderdByOfferType, getSelectedSlot, checkAddContent, getOfferPriceBySlots, getDefaultSlot,
} from 'lib/features/createOrderV2/helpers';
import { useAppSelector } from 'lib/hooks';
import { buildOrderFormSelector } from 'lib/features/createOrderV2/selectors';
import { getTableDate } from 'utils';
import { useGetDescription } from './useGetDescription';
import { getDefaultSelectedModelFormat, prepareModelFormats } from './helpers';
import { getOfferSlots, getSourceType } from './helpers';
import { Data } from '../types';
import { RighListComponent } from '../Right/types';
import { RightProps } from '../Right/types';

const prepareFileRightData = (file: File) => {
  const data: RightProps['list'] = [];
  if (!file) return data;

  const {
    id, transformedMetadata, type, createdAt, updatedAt, pipelineTypes,
  } = file;

  const { url, parameters } = transformedMetadata || {};

  if (id) data.push({ value: '-', title: 'Offer ID:' });

  const subTypeName = getValueOfferSubTypeName(type);
  if (subTypeName) data.push({ value: subTypeName, title: 'Offer Type:' });

  // if (providerInfo?.name) data.push({ value: providerInfo?.name, title: 'Provider:' });

  if (type === ValueOfferSubtype.ValueSubtypeModel && pipelineTypes?.length) {
    data.push({ value: pipelineTypes, title: 'Category:' });
  }

  if (parameters) data.push({ value: parameters, title: 'Parameters:' });

  const sourceType = getSourceType(url);
  if (url && sourceType) {
    data.push({
      value: url, component: RighListComponent.link, title: 'Source:', data: sourceType,
    });
  }

  data.push({ value: getTableDate(createdAt), title: 'Published:' });

  data.push({ value: getTableDate(updatedAt), title: 'Updated:' });

  return data;
};

const fileDataTransform = (data: File,   buildOrderForm: BuildOrderForm, description: Description): Data | undefined => {
  if (!data) return undefined;

  const {
    id, transformedMetadata, type, name,
  } = data;
  const slots = (transformedMetadata?.slots || [])
    .map(({ slots }) => slots.map((slot) => ({
        ...slot,
        id: slot.id || '',
        usage: { ...slot.usage, priceType: getPriceTypeBFFFromPriceTypeSDK(slot.usage.priceType) }
    })))
    .flat();
  const field = getFieldBuildOrderdByOfferType(type, 'ValueOfferSubtype');
  const selectedSlots = getSelectedSlot(buildOrderForm, field, id);
  const subType = getValueOfferSubTypeName(type);
  const modelFormats = prepareModelFormats(slots);
  const defaultSlot = getDefaultSlot(slots);
  const defaultSelectedModelFormat = getDefaultSelectedModelFormat(modelFormats, defaultSlot, selectedSlots);

  const isAdded = checkAddContent({
    formContent: buildOrderForm,
    field,
    offerId: id,
  });

  return {
    common: {
      price: getOfferPriceBySlots(selectedSlots, slots),
      restrictions: [],
      fieldBuildOrderForm: field,
      id,
      selectedSlots,
      isAdded,
    },
    left: {
      name,
      about: description,
      restricted: [],
      type: OfferType.Data,
      slots: getOfferSlots(slots, type),
      subType,
      options: [],
      modelFormats,
      defaultSelectedModelFormat,
    },
    right: {
      list: prepareFileRightData(data),
    },
  };
};

export const useGetFile = (id: string, skip = false) => {
  const { data } = useFile(skip ? null : id);
  const { description } = useGetDescription(data?.description);
  const buildOrderForm = useAppSelector(buildOrderFormSelector);

  const formatted = useMemo(() => {
    try {
      if (data) {
        return fileDataTransform(data, buildOrderForm, description);
      }
      return undefined;
    } catch (error) {
      console.error('Error prepare file data', error);
      return undefined;
    }
  }, [data, description, buildOrderForm]);

  return {
    formatted,
    original: data,
    isTee: false,
  };
};
