import { getOffersWithSlotsIds } from 'lib/features/createOrderV2/helpers';
import { useLazyGetMinimalConfigurationQuery } from 'generated/schemas/teeOffers';
import { validateRTKResponse } from 'lib/features/helpers';
import { ExtendedFormContent } from 'lib/features/createOrderV2/types';
import { useCallback } from 'react';
import {
  TeeOfferSubtype,
} from 'generated/types';
import { useGetTeeOfferFilterFromOfferConfiguration } from 'hooks/useGetTeeOfferFilterFromOfferConfiguration';

export const getPreparedRange = (value?: number | null): [number] | [] => {
  return [typeof value === 'number' ? value : 0];
};

// export const getFiltersFromMinimalConfiguration = (configuration?: OfferConfiguration): FormFiltersCompute => {
//   if (!configuration) return {};
//   const {
//     cpuCores, ram, diskUsage, bandwidth, traffic, externalPort, gpuCores, vram,
//   } = configuration || {};

//   return {
//     [FiltersFields.slots]: {
//       ...(isNumber(cpuCores) ? { cpuCores } : {}),
//       ...(isNumber(gpuCores) ? { gpuCores } : {}),
//       ...(isNumber(ram) ? { ram: parseFloat(round(getGb(ram))) } : {}),
//       ...(isNumber(vram) ? { vram: parseFloat(round(getGb(vram))) } : {}),
//       ...(isNumber(diskUsage) ? { diskUsage: parseFloat(round(getGb(diskUsage))) } : {}),
//     },
//     [FiltersFields.options]: {
//       ...(bandwidth ? { bandwidth: parseFloat(round(getMbit(bandwidth))) } : {}),
//       ...(traffic ? { traffic: parseFloat(round(getGbit(traffic))) } : {}),
//       externalPort: externalPort ? ExtPorts.yes : ExtPorts.any,
//     },
//   };
// };

// export const getPreparedFilterCompute = (filters: FormFiltersCompute): TeeOfferFilter => {
//   const {
//     slots, options, pricePerHour,
//   } = (filters || {}) as FormFiltersCompute;
//   const { externalPort, traffic, bandwidth } = options || {};
//   const {
//     ram, diskUsage, cpuCores, gpuCores, vram = 0,
//   } = slots || {};
//   return {
//     inactive: false,
//     enabled: true,
//     ...(externalPort === ExtPorts.yes ? { externalPort: externalPort ? 1 : 0 } : {}),
//     ...(isNumber(cpuCores) ? { cpuCores: getPreparedRange(cpuCores) } : {}),
//     ...(isNumber(gpuCores) ? { gpuCores: getPreparedRange(gpuCores) } : {}),
//     ...(isNumber(ram) ? { ram: getPreparedRange(getBytesFromGb(ram as number)) } : {}),
//     ...(isNumber(vram) ? { vram: getPreparedRange(getBytesFromGb(vram as number)) } : {}),
//     ...(isNumber(traffic) ? { traffic: getBytesFromGbit(traffic as number) } : {}),
//     ...(isNumber(diskUsage) ? { diskUsage: getPreparedRange(getBytesFromGb(diskUsage as number)) } : {}),
//     ...(isNumber(bandwidth) ? { bandwidth: getBytesFromMbit(bandwidth as number) } : {}),
//     ...(pricePerHour ? { pricePerHour: [pricePerHour] } : {}),
//     subType: TeeOfferSubtype.TeeSubtypeTdx,
//   };
// };

export const useGetComputeFilters = () => {
  const [getMinimalConfiguration] = useLazyGetMinimalConfigurationQuery();
  const { getFilters: getTeeOfferFilter } = useGetTeeOfferFilterFromOfferConfiguration();
  const getFilters = useCallback(async (extendedFormContent: ExtendedFormContent) => {
    const offersId = getOffersWithSlotsIds(extendedFormContent);
    const response = validateRTKResponse(await getMinimalConfiguration({ offers: offersId }));
    return {
      ...getTeeOfferFilter(response?.data?.result),
      subType: TeeOfferSubtype.TeeSubtypeTdx,
      inactive: false,
      enabled: true,
    };
  }, [getMinimalConfiguration, getTeeOfferFilter]);

  return {
    getFilters,
  };
};