import { useCallback } from 'react';

import { Paper } from 'uikitv2/Paper';
import { Box } from 'uikitv2/Box';
import { Counter } from 'uikitv2/Counter';
import { Divider } from 'uikitv2/Divider';
import { BilletValue } from 'components/BilletValue';
import { AdderBtn } from 'components/AdderBtn';
import classes from './Billet.module.scss';
import { BilletProps } from './types';

export const Billet = ({
  data,
  value,
  isSelected,
  loading,
  onAdd: onAddProp,
  onDelete: onDeleteProp,
  onDeploy: onDeployProp,
  isCanUpdate,
  isShowCounter,
}: BilletProps) => {
  const { id, title, list } = data;
  const onAdd = useCallback(() => onAddProp?.(id?.value), [onAddProp, id]);
  const onDelete = useCallback(() => onDeleteProp?.(id?.value), [onDeleteProp, id]);
  const onDeploy = useCallback(() => onDeployProp?.(id?.value), [onDeployProp, id]);

  return (
    <Paper className={classes.wrapper}>
      <Box className={classes.title} justifyContent="space-between" alignItems="center">
        <BilletValue
          {...title}
        />
        {!!(onAdd || onDelete || !!onDeploy) && isCanUpdate && (
          <AdderBtn
            disabled={loading}
            onAdd={onAddProp ? onAdd : undefined}
            onDeploy={onDeployProp ? onDeploy : undefined}
            onDelete={onDeleteProp ? onDelete : undefined}
            isAdded={isSelected}
            dataTestId={`offer-${id.value}-modal`}
            variantDeleteBtn="black3"
            size="small"
          />
        )}
        {isShowCounter && <Counter value={value} text="Added to order" />}
      </Box>
      <Divider className={classes.divider} />
      <Box className={classes.content}>
        <Box direction="row" className={classes['options-row']}>
          {list.map((options, index) => (
            <Box direction="column" className={classes['options-column']} key={index}>
              {options.map((item) => (
                <BilletValue
                  key={item.id}
                  className={classes.option}
                  {...item}
                />
              ))}
            </Box>
          ))}
          {list.length < 7 && list.length > 0 && (
            <>
              {Array.from(Array(7 - list.length).keys()).map((item) => (
                <div key={item} />
              ))}
            </>
          )}
        </Box>
        {!!id?.value && (
          <Box direction="column" justifyContent="flex-end" alignItems="flex-end">
            <BilletValue
              {...id}
            />
          </Box>
        )}
      </Box>
    </Paper>
  );
};
