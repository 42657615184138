import { OfferTypeTextBtn } from 'components/AdderBtn/AdderBtnOffer/types';

export enum RighListComponent {
  text = 'text',
  link = 'link',
}

interface BaseProp {
  value: string | string[];
  title: string;
}

interface LinkProp extends BaseProp {
  component: RighListComponent.link;
  data: string;
}

interface TextProp extends BaseProp {
  component?: RighListComponent.text;
  data?: never;
}

export interface RightProps {
  list?: (LinkProp | TextProp)[];
  price?: {
    type: string;
    sum: string;
  }[];
  onAdd?: () => void;
  onDelete?: () => void;
  loading?: boolean;
  isShowAdderBtn?: boolean;
  isAdded?: boolean;
  isShowAddBtn?: boolean;
  offerTextBtn?: OfferTypeTextBtn;
}
