import {
  useCallback, useEffect, useState,
} from 'react';
import { useDebouncedCallback } from 'use-debounce';
import { S3Credentials, StorageProviderResource, StorageType } from '@super-protocol/dto-js';
import {
  downloadFile,
} from 'utils/S3/S3';
import { useNotification } from 'hooks/useNotification';
import { JSONParseSafe } from 'utils';
import { streamToBuffer } from 'utils/stream';
import useAbort from 'hooks/useAbort';
import { Description, DescriptionType } from 'components/OfferAbout/types';
import { getS3ConfigFromCredentials } from 'utils/S3/utils';

export const getDefaultDescription = () => ({ value: '', type: DescriptionType.html });

export interface UseGetDescriptionResult {
  description: Description;
}

export const useGetDescription = (text?: string) => {
  const { showError } = useNotification();
  const { resetAbortController } = useAbort({ isAbortUnmount: true });
  const [description, setDescription] = useState<Description>(() => getDefaultDescription());

  const prepareDescription = useCallback(async (text?: string): Promise<Description> => {
    if (!text) return getDefaultDescription();
    const json: StorageProviderResource | null = JSONParseSafe(text);
    if (!json) return { value: text, type: DescriptionType.html };
    try {
      if (json.storageType !== StorageType.S3) {
        throw new Error('Storage type is not supported');
      }
      const file = await downloadFile({
        abortController: resetAbortController(),
        s3: getS3ConfigFromCredentials((json as StorageProviderResource<S3Credentials>).credentials),
        path: json.filepath,
      });
      if (!file) return getDefaultDescription();
      const description = (await streamToBuffer(file)).toString();
      return { value: description, type: DescriptionType.md };
    } catch (e) {
      console.error('Prepare description error: ', e);
      showError(e as Error);
      return getDefaultDescription();
    }
  }, [resetAbortController, showError]);

  const updateDescription = useCallback(async (text?: string) => {
    setDescription(await prepareDescription(text));
  }, [prepareDescription]);

  const updateDescriptionDebounce = useDebouncedCallback(updateDescription, 10);

  useEffect(() => {
    updateDescriptionDebounce(text);
  }, [updateDescriptionDebounce, text]);

  return {
    description,
  };
};