/* eslint-disable max-len */
import { ActionMeta, MultiValue, SingleValue } from 'react-select';
import { Theme } from 'uikit/common/types';

export type Option = {
  value: string | number;
  label: string;
}

export type OptionError = {
  value: string | number;
  error?: string;
}

export enum ValueType {
  object = 'object',
  string = 'string,'
}

export interface SelectUiProps {
  options: Option[];
  classNameWrap?: string;
  classNameIcon?: string;
  placeholder?: string;
  description?: string;
  theme?: Theme;
  onChange?: ((newValue: SingleValue<Option | string | number> | MultiValue<Option | string | number>, actionMeta: ActionMeta<Option | string | number>) => void) | undefined;
  defaultValue?: Option | Option[] | string;
  name?: string;
  isMulti?: boolean;
  value?: Option | Option[] | string | number;
  error?: string;
  optionsError?: OptionError[];
  mode?: 'create' | 'default' | 'async' | 'async-query';
  id?: string;
  label?: string;
  classNameLabel?: string;
  styles?: Record<string, any>;
  disabled?: boolean;
  maxMenuHeight?: number;
}

export interface SelectFormikProps extends SelectUiProps {
  name: string;
  checkTouched?: boolean;
  valueType?: ValueType;
}
