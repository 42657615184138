export enum SourceTypes {
  HUGGING_FACE = 'Hugging Face'
}

export enum SourceTypesUrls {
  'https://huggingface.co' = SourceTypes.HUGGING_FACE,
}

export enum SlotType {
  CPU = 'CPU',
  GPU = 'GPU'
}