import { memo } from 'react';
import Markdown, { Components } from 'react-markdown';
import remarkGfm from 'remark-gfm';
import rehypeRaw from 'rehype-raw';
import remarkFrontmatter from 'remark-frontmatter';

import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { tomorrowNight } from 'react-syntax-highlighter/dist/esm/styles/hljs';

import { MarkdownComponentProps } from './types';
import classes from './MarkdownComponent.module.scss';

const components: Components = {
  a: ({
    children,
    ...props
  }) => {
    return <a {...props} className={classes.link} target="_blank" rel="noopener noreferrer">{children}</a>;
  },
  h1: ({
    children,
    ...props
  }) => {
    return <h1 {...props} className={classes.h1}>{children}</h1>;
  },
  h2: ({
    children,
    ...props
  }) => {
    return <h2 {...props} className={classes.h2}>{children}</h2>;
  },
  h3: ({
    children,
    ...props
  }) => {
    return <h3 {...props} className={classes.h3}>{children}</h3>;
  },
  h4: ({
    children,
    ...props
  }) => {
    return <h4 {...props} className={classes.h4}>{children}</h4>;
  },
  h5: ({
    children,
    ...props
  }) => {
    return <h5 {...props} className={classes.h5}>{children}</h5>;
  },
  h6: ({
    children,
    ...props
  }) => {
    return <h6 {...props} className={classes.h6}>{children}</h6>;
  },
  p: ({
    children,
    ...props
  }) => {
    return <p {...props} className={classes.p}>{children}</p>;
  },
  ul: ({
    children,
    ...props
  }) => {
    return <ul {...props} className={classes.ul}>{children}</ul>;
  },
  li: ({
    children,
    ...props
  }) => {
    return <li {...props} className={classes.li}>{children}</li>;
  },
  table: ({
    children,
    ...props
  }) => {
    return <div className={classes['table-wrap']}><table {...props} className={classes.table}>{children}</table></div>;
  },
  td: ({
    children,
    ...props
  }) => {
    return <td {...props} className={classes.td}>{children}</td>;
  },
  th: ({
    children,
    ...props
  }) => {
    return <th {...props} className={classes.th}>{children}</th>;
  },
  hr: ({
    ...props
  }) => {
    return <hr {...props} className={classes.hr} />;
  },
  img: ({
    ...props
  }) => {
    return <img {...props} className={classes.img} />;
  },
  code: ({
    className, children, ...props
  }) => {
    const match = /language-(\w+)/.exec(className || '');
    return match ? (
      <SyntaxHighlighter
        style={tomorrowNight}
        
        language={match[1]}
        PreTag="div"
        className={classes['code-plugin']}
        {...props}
      >
        {String(children).replace(/\n$/, '')}
      </SyntaxHighlighter>
    ) : (
      <code className={classes.code} {...props}>
        {children}
      </code>
    );
  },
  blockquote: ({
    children,
    ...props
  }) => {
    return <blockquote {...props} className={classes.blockquote}>{children}</blockquote>;
  },
  pre: ({
    children,
    ...props
  }) => {
    return <pre {...props} className={classes.pre}>{children}</pre>;
  },
};

export const MarkdownComponent = memo<MarkdownComponentProps>(({ children }) => {
  return (
    <Markdown
      remarkPlugins={[remarkGfm, remarkFrontmatter]}
      rehypePlugins={[rehypeRaw]}
      components={components}
    >
      {children}
    </Markdown>
  );
});
